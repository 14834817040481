import PubSub from "pubsub-js";
import { useEffect } from "react";

// const PubSub = require('pubsub-js');

// Definisi interface untuk tipe data
interface OrderData {
  orderId: string;
  fileUrl: string;
}

interface PubSubMessage {
  action:
    | "REQUEST_ORDER"
    | "RESPONSE_ORDER"
    | "PRINT_ORDER"
    | "SUCCESS_PRINT"
    | "ERROR_PRINT";
  printerId?: string;
  orderId?: string;
  orderData?: OrderData | OrderData[];
  errorMessage?: string;
}

class SevendaysPubSub {
  // Daftar topik yang digunakan
  private static TOPICS = {
    REQUEST_ORDER: "REQUEST_ORDER",
    RESPONSE_ORDER: "RESPONSE_ORDER",
    PRINT_ORDER: "PRINT_ORDER",
    SUCCESS_PRINT: "SUCCESS_PRINT",
    ERROR_PRINT: "ERROR_PRINT",
  };

  // Konstruktor
  constructor() {
    this.initializeSubscriptions();
  }

  // Inisialisasi subscriptions default
  private initializeSubscriptions(): void {
    // Contoh subscription untuk REQUEST_ORDER
    PubSub.subscribe(
      SevendaysPubSub.TOPICS.REQUEST_ORDER,
      this.handleRequestOrder
    );

    // Subscription untuk print events
    PubSub.subscribe(SevendaysPubSub.TOPICS.PRINT_ORDER, this.handlePrintOrder);
    PubSub.subscribe(
      SevendaysPubSub.TOPICS.SUCCESS_PRINT,
      this.handleSuccessPrint
    );
    PubSub.subscribe(SevendaysPubSub.TOPICS.ERROR_PRINT, this.handleErrorPrint);
  }

  // Handler untuk REQUEST_ORDER
  public handleRequestOrder = async (
    msg: string,
    data: PubSubMessage
  ): Promise<void> => {
    console.log("Request Order Received");
    if (data.printerId) {
      console.log(`Received REQUEST_ORDER from printer ${data.printerId}`);

      // Dapatkan order yang tersedia
      const availableOrders = await this.getAvailableOrders();

      // Publish response order
      if (availableOrders.length > 0) {
        this.sendResponseOrder(availableOrders);
      }
    }
  };

  // Handler untuk PRINT_ORDER
  private handlePrintOrder = (msg: string, data: PubSubMessage): void => {
    console.log("Print Order Received:", data);
    // Tambahkan logika untuk memproses print order
  };

  // Handler untuk SUCCESS_PRINT
  private handleSuccessPrint = (msg: string, data: PubSubMessage): void => {
    if (data.orderId && data.printerId) {
      console.log(
        `Order ${data.orderId} printed successfully by printer ${data.printerId}`
      );
    }
  };

  // Handler untuk ERROR_PRINT
  private handleErrorPrint = (msg: string, data: PubSubMessage): void => {
    if (data.orderId && data.printerId && data.errorMessage) {
      console.error(
        `Error printing order ${data.orderId} by printer ${data.printerId}: ${data.errorMessage}`
      );
    }
  };

  // Mendapatkan order yang tersedia
  private async getAvailableOrders(): Promise<OrderData[]> {
    // Implementasi untuk mendapatkan order dari database atau sumber data
    return [
      { orderId: "123", fileUrl: "http://example.com/order-123.pdf" },
      { orderId: "456", fileUrl: "http://example.com/order-456.pdf" },
    ];
  }

  // Mengirim RESPONSE_ORDER
  public sendResponseOrder(orderData: OrderData[]): void {
    const message: PubSubMessage = {
      action: "RESPONSE_ORDER",
      orderData: orderData,
    };

    PubSub.publish(SevendaysPubSub.TOPICS.RESPONSE_ORDER, message);
  }

  // Mengirim PRINT_ORDER
  public sendPrintOrder(orderData: OrderData): void {
    const message: PubSubMessage = {
      action: "PRINT_ORDER",
      orderData: {
        orderId: orderData.orderId,
        fileUrl: orderData.fileUrl,
      },
    };

    PubSub.publish(SevendaysPubSub.TOPICS.PRINT_ORDER, message);
  }

  // Mengirim SUCCESS_PRINT
  public sendSuccessPrint(orderId: string, printerId: string): void {
    const message: PubSubMessage = {
      action: "SUCCESS_PRINT",
      orderId,
      printerId,
    };

    PubSub.publish(SevendaysPubSub.TOPICS.SUCCESS_PRINT, message);
  }

  // Mengirim ERROR_PRINT
  public sendErrorPrint(
    orderId: string,
    printerId: string,
    errorMessage: string
  ): void {
    const message: PubSubMessage = {
      action: "ERROR_PRINT",
      orderId,
      printerId,
      errorMessage,
    };

    PubSub.publish(SevendaysPubSub.TOPICS.ERROR_PRINT, message);
  }

  // Metode untuk melakukan subscribe kustom
  public subscribe(
    topic: string,
    callback: (msg: string, data: any) => void
  ): void {
    PubSub.subscribe(topic, callback);
  }

  // Metode untuk melakukan unsubscribe
  public unsubscribe(token: any): void {
    PubSub.unsubscribe(token);
  }

  public debugPubSub(): void {
    console.group('🔍 PubSub Debug Information');
    console.log('Singleton Instance:', this);
    console.groupEnd();
  }
}

// Singleton instance
const sevendaysPubSub = new SevendaysPubSub();
export default sevendaysPubSub;

export const usePubSubListener = () => {
  useEffect(() => {
    // Log saat hook diinisialisasi
    console.log("🌐 PubSub Global Listener Initialized");

    // Subscription untuk semua topik dengan logging komprehensif
    const subscriptions = [
      {
        topic: "REQUEST_ORDER",
        handler: (msg: string, data: PubSubMessage) => {
          console.group("🔔 REQUEST_ORDER Received");
          console.log("Raw Message:", msg);
          console.log("Full Data:", JSON.stringify(data, null, 2));
          console.log("Printer ID:", data.printerId);
          console.groupEnd();

          // Optional: Trigger response order logic if needed
          if (data.printerId) {
            sevendaysPubSub.handleRequestOrder(msg, data);
          }
        },
      },
      {
        topic: "RESPONSE_ORDER",
        handler: (msg: string, data: PubSubMessage) => {
          console.group("✅ RESPONSE_ORDER Received");
          console.log("Raw Message:", msg);
          console.log("Full Data:", JSON.stringify(data, null, 2));
          console.log("Available Orders:", data.orderData);
          console.groupEnd();
        },
      },
      {
        topic: "PRINT_ORDER",
        handler: (msg: string, data: PubSubMessage) => {
          console.group("🖨️ PRINT_ORDER Received");
          console.log("Raw Message:", msg);
          console.log("Full Data:", JSON.stringify(data, null, 2));
          console.groupEnd();
        },
      },
      {
        topic: "SUCCESS_PRINT",
        handler: (msg: string, data: PubSubMessage) => {
          console.group("✨ SUCCESS_PRINT Received");
          console.log("Raw Message:", msg);
          console.log("Full Data:", JSON.stringify(data, null, 2));
          console.log(`Order ${data.orderId} printed by ${data.printerId}`);
          console.groupEnd();
        },
      },
      {
        topic: "ERROR_PRINT",
        handler: (msg: string, data: PubSubMessage) => {
          console.group("❌ ERROR_PRINT Received");
          console.error("Raw Message:", msg);
          console.error("Full Data:", JSON.stringify(data, null, 2));
          console.error(
            `Error printing order ${data.orderId} by ${data.printerId}`
          );
          console.groupEnd();
        },
      },
    ];

    // Simpan token-token subscription
    const tokens = subscriptions.map((sub) =>
      PubSub.subscribe(sub.topic, sub.handler)
    );

    // Global error handler
    const globalErrorHandler = (msg: string, data: any) => {
      console.group("🌍 GLOBAL PUBSUB CATCH");
      console.warn("Unhandled Message:", msg);
      console.warn("Unhandled Data:", data);
      console.groupEnd();
    };

    // Subscribe ke semua pesan yang tidak tertangani
    PubSub.subscribe("*", globalErrorHandler);

    // Cleanup function
    return () => {
      console.log("🧹 PubSub Listeners Cleaned Up");

      // Unsubscribe dari semua token spesifik
      tokens.forEach((token) => PubSub.unsubscribe(token));

      // Unsubscribe global error handler
      PubSub.unsubscribe(globalErrorHandler);
    };
  }, []); // Dependency array tetap kosong
};
