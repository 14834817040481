import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap"; 
import ReprintModal from "../modal/ReprintModal"

type Props = {
  data?: any;
};

const ActionsCell: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const [isReprintModalOpen,setReprintModalOpen]=useState<boolean>(false);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="secondary"
          id="dropdown-basic"
          data-testid="dropdownToggleAction"
          className="px-2 py-1 fs-7 my-4"
        >
          {t("Marketplace.Table.Header.Action")}
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-light text-center">
          <div>
            <div className="dropdown-item btn btn-active-light btn-sm">
              <button
                type="button"
                className="btn btn-sm"
                data-testid="reprint-button"
                onClick={()=>setReprintModalOpen(true)}
              >
                {t("Value.Pack.Reprint")}
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {isReprintModalOpen&&<ReprintModal orderIDs={data?.printedOrderIds} onClose={()=>setReprintModalOpen(false)}/>}
    </>
  );
};

export { ActionsCell };
