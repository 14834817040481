import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { scanBarcode } from "src/api/server/prepacks";
import ShippingMarkModal from "./ShippingMarkModal";
import axios from "axios";
import db from "src/db";
import * as lc from "../../../../../app/modules/localstorage/index";

/**
 * ScanLocatorModalProps Interface
 */
type ScanLocatorModalProps = {
  onClose: () => void;
  onSubmit?: (data: { locator: string; sku: string }) => void;
};

/**
 * ScanLocatorModal is a modal component that allows the user to scan a locator and input a SKU.
 * It will validate the locator and SKU and submit the form if both are valid.
 * It also handles errors and displays them to the user.
 *
 * @param {function} onClose - A function to call when the modal is closed.
 * @param {function} onSubmit - A function to call when the form is submitted.
 * @returns {JSX.Element} - The modal component.
 */
const ScanLocatorModal: React.FC<ScanLocatorModalProps> = ({
  onClose,
  onSubmit,
}) => {
  // Translation hook
  const { t } = useTranslation();

  // Input reference hooks
  const locatorInputRef = useRef<HTMLInputElement>(null);
  const skuInputRef = useRef<HTMLInputElement>(null);

  // State management
  const [locator, setLocator] = useState<string>("");
  const [sku, setSku] = useState<string>("");
  const [isLocatorDisabled, setIsLocatorDisabled] = useState<boolean>(false);
  const [isSkuDisabled, setIsSkuDisabled] = useState<boolean>(false);

  // Loading states
  const [isLocatorLoading, setIsLocatorLoading] = useState<boolean>(false);
  const [isSkuLoading, setIsSkuLoading] = useState<boolean>(false);

  // Error state
  const [error, setError] = useState<{
    locator?: string;
    sku?: string;
  }>({});

  // Modal state
  const [isShippingMarkModalOpen, setIsShippingMarkModalOpen] =
    useState<boolean>(false);

  const [printerUrl, setPrinterUrl] = useState<string | null>(null);

  useEffect(() => {
    // Periksa apakah printerUrl sudah ada di localStorage
    const storedUrl = localStorage.getItem("printerUrl");

    if (storedUrl) {
      // Jika ada, langsung gunakan nilai dari localStorage
      setPrinterUrl(storedUrl);
    } else {
      // Jika tidak ada, ambil dari Firestore dan simpan ke localStorage
      const fetchPrinterUrl = async () => {
        const url = await db
          .collection("clients")
          .doc(lc.getClientId())
          .collection("api")
          .doc("push")
          .get()
          .then((doc) => doc.data()?.printer?.url);

        if (url) {
          // Simpan URL ke localStorage untuk penggunaan berikutnya
          localStorage.setItem("printerUrl", url);
          setPrinterUrl(url);
        }
      };

      fetchPrinterUrl();
    }
  }, []);

  // Auto-focus locator input on component mount
  useEffect(() => {
    locatorInputRef.current?.focus();
  }, []);

  /**
   * A simple loading spinner component that displays a small, primary-colored spinner.
   * The spinner is wrapped in a <div> with a "spinner-border" class and an aria role of "status".
   * The spinner itself is given a "spinner-border-sm" class to make it smaller.
   * The loading text is hidden from screen readers with the "visually-hidden" class.
   */
  const LoadingSpinner = () => (
    <div
      className="spinner-border spinner-border-sm text-primary"
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  /**
   * Handles changes to the locator input field.
   * Updates the locator state with the new value from the input.
   * Clears any previous locator errors.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event from the locator input.
   */
  const handleLocatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocator(value);
    // Clear previous locator errors
    setError((prev) => ({ ...prev, locator: undefined }));
  };

  /**
   * Handles the blur event for the locator input field.
   * Validates the locator and updates the error state if invalid.
   * If the locator is valid, it locks the locator input and focuses on the SKU input.
   * Sets loading state during the validation process.
   * Handles errors such as locator not found or insufficient stock, and updates the error state accordingly.
   * Clears the locator input and focuses it if validation fails.
   * Logs warnings in case of any unexpected errors during the process.
   */
  const handleLocatorBlur = async () => {
    if (!locator.trim()) {
      setError((prev) => ({
        ...prev,
        locator: t("Value.Pack.Enter.Locator"),
      }));
      return;
    }

    // Set loading state
    setIsLocatorLoading(true);
    setError({});

    try {
      const isValidLocator = await scanBarcode(1, locator);

      // Clear loading state
      setIsLocatorLoading(false);

      // Handle errors if any error response
      if (isValidLocator?.statusCode === 400) {
        if (isValidLocator?.error?.code === "LOCATOR_NOT_FOUND") {
          setError((prev) => ({
            ...prev,
            locator:
              t("Value.Pack.Error.Locator") +
              locator +
              t("Value.Pack.Error.Locator.Detail"),
          }));
        } else if (isValidLocator?.error?.code === "INSUFFICIENT_STOCK") {
          setError((prev) => ({
            ...prev,
            locator:
              t("Value.Pack.Error.Locator.Qty") +
              locator +
              t("Value.Pack.Error.Locator.Detail"),
          }));
        }
        setLocator("");
        locatorInputRef.current?.focus();
        return;
      }

      // Lock locator and focus SKU input
      setIsLocatorDisabled(true);
      setTimeout(() => {
        skuInputRef.current?.focus();
      }, 0);
    } catch (error: any) {
      console.warn(error);
      // Clear loading state on error
      setIsLocatorLoading(false);
    }
  };

  // Edit locator handler
  const handleEditLocator = () => {
    setIsLocatorDisabled(false);
    setSku("");
    setError({});
    locatorInputRef.current?.focus();
  };

  // SKU input handler
  const handleSkuInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredSku = e.target.value;
    setSku(enteredSku);
    setError((prev) => ({ ...prev, sku: undefined }));
  };

  /**
   * Handles form submission.
   * Validates locator and SKU inputs before submission.
   * Submits request to server to scan SKU if inputs are valid.
   * Handles errors if any error response, and updates the error state accordingly.
   * If the submission is successful, it calls the onSubmit callback with the locator and SKU values.
   * Disables SKU input during submission and re-enables it after completion.
   */
  const handleSubmit = async () => {
    // Remove default error first
    setError({
      locator: "",
      sku: "",
    });

    // Validate locator inputs before submission
    if (!locator) {
      setError((prev) => ({
        ...prev,
        locator: t("Validation.Locator.Required"),
      }));
      locatorInputRef.current?.focus();
      return;
    }

    // Validate sku inputs before submission
    if (!sku) {
      setError((prev) => ({ ...prev, sku: t("Validation.SKU.Required") }));
      skuInputRef.current?.focus();
      return;
    }

    // Set loading state
    setIsSkuLoading(true);

    try {
      // Lock SKU input
      setIsSkuDisabled(true);
      const skuToScan = sku;
      setSku("");

      // Request server to scan SKU
      // const isValidLocator = await scanBarcode(2, locator, skuToScan);
      const isValidLocator: any = await axios.post(`${printerUrl}/api/print`, {
        sku: skuToScan,
      });

      // Clear loading state
      setIsSkuLoading(false);
      console.log("Response From Printer : ", isValidLocator);

      // Handle errors if any error response
      if (isValidLocator?.status !== 200) {
        if (isValidLocator?.data?.code === "ORDER_ALREADY_PRINTED") {
          const ordMessage = isValidLocator?.data.message || "";
          const orderNumber = ordMessage.split(":")[1].trim();
          const errorMessage =
            t("Value.Pack.Error.Printed") + " : " + orderNumber;

          setError((prev) => ({
            ...prev,
            sku: errorMessage,
          }));
        } else if (isValidLocator?.data?.code === "SHIPPING_LABEL_NOT_PDF") {
          setError((prev) => ({
            ...prev,
            sku: t("Value.Pack.Error.Shipping.Label"),
          }));
        } else if (
          isValidLocator?.data?.code === "ORDER_STATUS_NOT_READY_TO_SHIP"
        ) {
          const ordMessage = isValidLocator?.data.message || "";
          const orderNumber = ordMessage.split(":")[1].trim();
          const errorMessage =
            t("Value.Pack.Error.Order.Status") + " : " + orderNumber;

          setError((prev) => ({
            ...prev,
            sku: errorMessage,
          }));
        } else if (
          isValidLocator?.data?.code === "FAILED_TO_SAVE_PREPACK_LABEL"
        ) {
          setError((prev) => ({
            ...prev,
            sku: t("Value.Pack.Error.Saving"),
          }));
        } else if (isValidLocator?.data?.code === "NO_ORDER_FOUND") {
          setError((prev) => ({
            ...prev,
            sku: isValidLocator?.data?.message,
          }));
        } else {
          setError((prev) => ({
            ...prev,
            sku: isValidLocator?.data?.message,
          }));
        }
        
      } else {
        // Successful submission logic
        onSubmit?.({ locator, sku: skuToScan });
      }

      setIsSkuDisabled(false);
      skuInputRef.current?.focus();
    } catch (error: any) {
      console.warn(error);
      // Clear loading state on error
      setIsSkuLoading(false);
      setIsSkuDisabled(false);
      skuInputRef.current?.focus();
    }
  };

  return (
    <>
      <div
        className="modal-backdrop show"
        data-testid="scan-locator-modal"
      ></div>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">{t("Value.Pack.Scan.Locator")}</h5>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
              {/* Locator Input */}
              <div className="mb-3">
                <label htmlFor="locator-input" className="form-label">
                  {t("Value.Pack.Locator")}
                </label>
                <div className="input-group">
                  <input
                    id="locator-input"
                    ref={locatorInputRef}
                    type="text"
                    className={`form-control ${
                      error.locator ? "is-invalid" : ""
                    }`}
                    value={locator}
                    onChange={handleLocatorChange}
                    onBlur={handleLocatorBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleLocatorBlur();
                      }
                    }}
                    disabled={isLocatorDisabled || isLocatorLoading}
                    placeholder={t("Value.Pack.Enter.Locator")}
                  />
                  {isLocatorLoading && (
                    <div className="input-group-text">
                      <LoadingSpinner />
                    </div>
                  )}
                  {isLocatorDisabled && (
                    <button
                      className="btn btn-transparent"
                      onClick={handleEditLocator}
                    >
                      <i className="bi bi-pencil ms-2 fs-3" />
                    </button>
                  )}
                  {error.locator && (
                    <div className="invalid-feedback">{error.locator}</div>
                  )}
                </div>
              </div>

              {/* SKU Input */}
              <div className="mb-3">
                <label htmlFor="sku-input" className="form-label">
                  {t("Value.Pack.SKU")}
                </label>
                <div className="input-group">
                  <input
                    id="sku-input"
                    ref={skuInputRef}
                    type="text"
                    className={`form-control ${error.sku ? "is-invalid" : ""}`}
                    value={sku}
                    onChange={handleSkuInput}
                    onBlur={handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                    disabled={
                      !isLocatorDisabled || isSkuDisabled || isSkuLoading
                    }
                    placeholder={t("Value.Pack.Enter.SKU")}
                  />
                  {isSkuLoading && (
                    <div className="input-group-text">
                      <LoadingSpinner />
                    </div>
                  )}
                  {error.sku && (
                    <div className="invalid-feedback">{error.sku}</div>
                  )}
                </div>
              </div>
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
              <button
                className="btn btn-light-primary"
                style={{ marginRight: "auto" }}
                onClick={() => setIsShippingMarkModalOpen(true)}
              >
                {t("Shipping.Handover.Mark.Add")}
              </button>
              <button
                onClick={handleSubmit}
                id="submit-button"
                className="btn btn-primary"
                disabled={isSkuLoading}
              >
                {t("Common.Button.Submit")}
              </button>
              <button onClick={onClose} className="btn btn-light-primary">
                {t("Common.Button.Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Shipping Mark Modal */}
      {isShippingMarkModalOpen && (
        <ShippingMarkModal onClose={() => setIsShippingMarkModalOpen(false)} />
      )}
    </>
  );
};

export default ScanLocatorModal;
