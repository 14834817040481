import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../core";
import { useMediaQuery } from "react-responsive";
import { ActionsCell } from "./components/ActionsCell";
import ListComponent from "src/app/components/list/NewList";
import "src/styles/css/product.css";

import ValuePack from "src/app/models/Prepacks";
import ScanLocatorModal from "./modal/ScanLocatorModal";
import ShippingMarkModal from "./modal/ShippingMarkModal";
import OrderIDModal from "./modal/OrderIDModal";
import { format } from "date-fns";

type Columns<T> = {
  title: string;
  field: keyof T;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

type Action<T> = {
  headerTitle: string;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

/**
 * Table Value Pack That Showing Product Name, Product SKU, Order Quantity
 *
 * @returns {*}
 */
const ValuePackPage = () => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });

  const [isScanLocatorModalOpen, setIsScanLocatorModalOpen] =
    useState<boolean>(false);
  const [isShippingMarkModalOpen, setIsShippingMarkModalOpen] =
    useState<boolean>(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<String[]>([]);

  /**
   * Breadcrumbs for Collection Prepack
   *
   * @type {Array<PageLink>}
   */
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Value.Pack.Breadcrumb"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  /**
   * Default Column For Collection Prepack
   * Displaying Product Name, Product SKU, Order Quantity
   * @type {Columns<ValuePack>[]}
   */
  const defaultColumns: Columns<ValuePack>[] = [
    {
      field: "productName",
      title: t("Value.Pack.ProductName"),
      value: (props: any) => { 
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end position-sticky"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder position-sticky"
            }
          >
            { props.row?.original?.productName}
          </div>
        );
      },
    },
    {
      field: "productSKU",
      title: t("Value.Pack.ProductSKU"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end position-sticky"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder position-sticky"
            }
          >
            { props.row?.original?.productSKU}
          </div>
        );
      },
    },
    {
      field: "orderIds",
      title: t("Value.Pack.TotalOrder"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end position-sticky"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder position-sticky"
            }
          >
            <div className="ms-5 my-5">
              <p style={{ display: "inline-block", marginRight: "10px" }}>
                {props.row?.original?.orderIds?.length}
              </p>
              <button
                className="btn btn-transparent"
                style={{ display: "inline-block", verticalAlign: "middle" }}
                onClick={() =>
                  handleShowOrderModal(props.row?.original?.orderIds)
                }
              >
                <i className="bi bi-eye fs-3" />
              </button>
            </div>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      title: "Last Updated",
      value: (props: any) => {
        return (
          <>
            <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
              {props?.value?.seconds
                ? format(new Date(props?.value?.seconds * 1000), "dd/MM/yyyy")
                : " - "}
            </p>
            <p className="p-0 m-0">
              {props?.value?.seconds
                ? format(new Date(props?.value?.seconds * 1000), "HH:mm")
                : " - "}
            </p>
          </>
        );
      },
    },
  ];

  /**
   * Buttons For Reprint Label
   *
   * @type {Action<ValuePack>}
   */
  const defaultAction: Action<ValuePack> = {
    headerTitle: t("Common.Action"),
    value: (props: any) => {
      return (
        <div className="p-0 m-2">
          <ActionsCell data={props.row?.original} />
        </div>
      );
    },
  };

  const handleShowOrderModal = (orderId: String[]) => {
    setOrder(orderId);
    setIsOrderModalOpen(true);
  };
 

  return (
    <>
      <PageTitle
        breadcrumbs={usersBreadcrumbs}
        data-testid="value-pack-page-title"
      >
        {t("Value.Pack.Title")}
      </PageTitle>
      <ListComponent<ValuePack>
        collection="prepacks"
        collectionGroup={false}
        columns={defaultColumns}
        selection={{
          headerSelection: false,
          rowSelection: false,
        }}
        defaultFilters={[
          {
            label: "isActive",
            field: "isActive",
            type: "boolean",
            value: true,
          }
        ]}
        defaultSorting={[
          {
            field: "updatedAt",
            label: "updatedAt",
            direction: "asc",
          },
        ]}
        useFilter={[]}
        searching={{
          field: "productSKU",
          useFirestore: true,
          value: "",
          caseSensitive: false,
        }}
        extra={[
          {
            label: "scan_locator",
            value: (props: any) => {
              return (
                <>
                  <button
                    type="button"
                    data-testid="btnScanLocator"
                    className="btn btn-primary"
                    onClick={() => setIsScanLocatorModalOpen(true)}
                  >
                    {t("Value.Pack.Scan.Locator")}
                  </button>
                </>
              );
            },
          },
          {
            label: "create_shipping",
            value: (props: any) => {
              return (
                <>
                  <button
                    type="button"
                    data-testid="btnCreateShipping"
                    className="btn btn-light-primary"
                    onClick={() => setIsShippingMarkModalOpen(true)}
                  >
                    {t("Shipping.Handover.Mark.Add")}
                  </button>
                </>
              );
            },
          },
        ]}
        action={defaultAction}
      />

      {isScanLocatorModalOpen && (
        <ScanLocatorModal onClose={() =>setIsScanLocatorModalOpen(false)} />
      )}
      {isShippingMarkModalOpen && (
        <ShippingMarkModal onClose={() => setIsShippingMarkModalOpen(false)} />
      )}
      {order && isOrderModalOpen && (
        <OrderIDModal
          orderIDs={order}
          onClose={() => setIsOrderModalOpen(false)}
        />
      )}
    </>
  );
};

export default ValuePackPage;
